import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: 0,
      paddingRight: 0,
      height: "100%",
      minHeight: "100vh",
      backgroundColor: "#fff",
    },
    headerBackground: {
      width: "100%",
    },
    headerBackgroundWrapper: {
      width: "100%",
      height: "210px",
      background:
        "linear-gradient(180deg, #242424 0%, rgba(36, 36, 36, 0.91) 100%)",
      paddingTop: "8px",
    },
    backgroundWrapper: {
      display: "flex",
      justifyContent: "center",
    },
    logo: {
      position: "absolute",
      top: 0,
      left: 0,
      marginTop: 24,
      marginLeft: 16,
      zIndex: 1000,
      height: 24,
    },
    headerWrapper: {
      position: "relative",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    contentWrapper: {
      padding: "0 16px",
    },
    searchLocationWrapper: {
      backgroundColor: "#ffffff",
      boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.04)",
      borderRadius: 10,
      marginTop: "-58px",
      padding: 16,
    },
    rootWrapper: {
      position: "relative",
      backgroundColor: "#FFFFFF",
      borderRadius: "20px 20px 0 0",
      paddingBottom: 60,
      paddingTop: 16,
    },
    searchSection: {
      position: "relative",
      zIndex: 1,
      borderRadius: "25px 25px 0 0",
      backgroundColor: "#ffffff",
      paddingTop: "18px",
      paddingRight: "16px",
      paddingLeft: "16px",
      paddingBottom: 0,
    },
    searchKeterangan: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      marginTop: 22,
    },
    wrapperSeacrhField: {
      background: "rgba(255, 255, 255, 0.41)",
      boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
      backdropFilter: "blur(15px)",
      borderRadius: 32,
      padding: "9px 16px",
      marginTop: 0,
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
      width: "90%",
      marginBottom: "14px",
      gap: "16px",
    },
    divider: {
      width: "50px",
      height: "4px",
      borderRadius: "10px",
      background: "#F5F5F5",
      marginLeft: "auto",
      marginRight: "auto",
    },
    searchIcon: {
      color: "#FFF",
      background: "rgba(255, 255, 255, 0.1)",
      boxShadow: "0px 5px 10px rgba(42, 90, 139, 0.08)",
    },
    searchField: {
      width: "100%",
      color: "#FFF",
      fontSize: "15px !important",
      fontWeight: "700 !important",
      lineHeight: "18px",
    },
    listPlaceWrapper: {
      display: "flex",
      overflowX: "auto",
      "&::-webkit-scrollbar": {
        background: "transparent",
      },
      padding: "16px 0",
      marginRight: -16,
    },
    placeItem: {
      background: "#FFFFFF",
      boxShadow:
        "0px 0px 1px rgba(42, 90, 139, 0.08), 0px 4px 15px rgba(42, 90, 139, 0.06)",
      alignItems: "center",
      borderRadius: 14,
      display: "flex",
      padding: "8px 16px",
      cursor: "pointer",
    },
    loginWrapper: {
      display: "flex",
    },
    textLogin: {
      color: "#2f9cf1",
      paddingLeft: 10,
      cursor: "pointer",
    },
    searchWrapper: {
      backgroundColor: "#ffffff",
      boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.04)",
      borderRadius: 10,
      marginTop: 25,
      padding: 16,
    },
    areaSearch: {
      display: "flex",
      alignItems: "flex-start",
      cursor: "pointer",
      width: "100%",
    },
    areaSearchSingle: {
      display: "flex",
      alignItems: "center",
      width: "100%",
      marginBottom: 8,
    },
    searchIcon: {
      height: 20,
      width: 20,
    },
    calendarIcon: {
      margin: 2,
      height: 20,
      width: 20,
    },
    checkInOutWrapper: {
      display: "flex",
      paddingLeft: 16,
      width: "100%",
    },
    date: {
      color: "#a0a4a8",
      display: "block",
      fontSize: "0.68rem",
    },
    dateRange: {
      color: "#a0a4a8",
      alignItems: "center",
      display: "flex",
      padding: "0 10px",
    },
    dateRangeWrapper: {
      alignItems: "center",
      display: "flex",
    },
    buttonSearch: {
      backgroundColor: "#2f9cf1",
      textTransform: "capitalize",
      color: "#ffffff",
      fontWeight: "bold",
      marginTop: 16,
      paddingTop: 13,
      paddingBottom: 16,
      "&:hover": {
        backgroundColor: "#2880c5",
      },
    },
    pilihLayananmu: {
      fontWeight: "bold",
    },
    pilihLayananWrapper: {
      padding: "0px 15px",
      backgroundColor: "#ffffff",
    },
    buttonPilihanLayanan: {
      display: "block !important",
      textTransform: "capitalize !important",
      borderRadius: "10px !important",
      padding: "10px !important",
    },
    buttonDefault: {
      display: "block",
      textTransform: "capitalize",
      borderRadius: 10,
      padding: 0,
      textAlign: "initial",
    },
    olehOlehPopuler: {
      fontSize: "14px !important",
      fontWeight: "700 !important",
    },
    menuWrapper: {
      backgroundColor: "#ffffff",
      paddingTop: "25px",
      paddingRight: "15px",
      paddingLeft: "15px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    cardOlehOleh: {
      background: "#FFFFFF",
      borderRadius: 10,
      width: "fit-content",
      height: 120,
    },
    cardContainer: {
      paddingTop: 10,
      paddingLeft: 16,
      background: "#FFFFFF",
      display: "flex",
      overflowX: "auto",
      "&::-webkit-scrollbar": {
        background: "transparent",
      },
      overflowY: "hidden",
    },
    bold: {
      fontWeight: "bold !important",
    },
    titleCard: {
      color: "#52575c",
      paddingBottom: 8,
    },
    titleCardSingle: {
      paddingBottom: 8,
      fontSize: 16,
      fontWeight: 700,
    },
    totalNight: {
      position: "absolute",
      right: 0,
      paddingTop: 10,
      padding: "0 8px",
      textAlign: "center",
      backgroundColor: "#ffffff",
    },
    searchLabel: {
      width: "100%",
      borderBottom: "1px solid rgba(0,0,0,.08)",
      marginBottom: 8,
      paddingBottom: 8,
    },
    searchLabelSingle: {
      width: "100%",
      marginBottom: 0,
      paddingBottom: 0,
    },
    flexAlignCenter: {
      display: "flex",
      alignItems: "center",
    },
    dataCheckinWrapper: {
      background: "#FFFFFF",
      boxShadow: "0px 5px 20px rgba(0, 0, 0, 0.04)",
      borderRadius: 10,
      padding: 16,
    },
    iconCheckin: {
      transform: "scale(1.3)",
      "& path": {
        fill: theme.palette.secondary.main,
      },
    },
    cardOlehOlehWithMargin: {
      background: "#FFFFFF",
      borderRadius: 10,
      height: 120,
      width: 180,
      marginLeft: 8,
    },
    cardOlehOlehWithoutMargin: {
      background: "#FFFFFF",
      borderRadius: 10,
      height: 120,
      width: 180,
    },
    cardFood: {
      background: "#FFFFFF",
      boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.1)",
      borderRadius: 10,
      display: "flex",
      width: 250,
      cursor: "pointer",
      marginBottom: 16,
    },
    cardSouvenir: {
      background: "#FFFFFF",
      boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.1)",
      borderRadius: 10,
      width: 150,
      cursor: "pointer",
      marginBottom: 16,
    },
    allowLocation: {
      position: "fixed",
      padding: 20,
      marginLeft: 20,
      marginTop: 20,
      zIndex: 1,
    },
    paginationImg: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginBottom: 12,
      marginLeft: 10,
      marginTop: -130,
      position: "relative",
    },
    dot: {
      backgroundColor: "#e4e6e7",
      height: 6,
      width: 6,
      borderRadius: 6,
      margin: 3,
    },
    dotActive: {
      backgroundColor: "#FFFFFF",
      height: 6,
      width: 20,
      borderRadius: 6,
      margin: 3,
    },
    rootCard: {
      margin: "auto",
      padding: 0,
      boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1) !important",
      borderRadius: "8px !important",
    },
    menuWaletVoucherWraper: {
      backgroundColor: "#ffffff",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "50%",
      cursor: "pointer",
      padding: "16px !important",
    },
    membershipWraper: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "0 15px 15px 15px",
    },
    progressRoot: {
      backgroundColor: "#E8E8E8 !important",
      height: "5px !important",
      borderRadius: "2px",
    },
    groupWraper: {
      width: "100%",
      maxWidth: "340px",
    },
    groupTypography: {
      fontSize: "14px !important",
      fontWeight: "500 !important",
      color: "#52575C",
      marginBottom: "4px !important",
    },
    gridLayananWithMargin: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginTop: 10,
    },
    gridLayananWithoutMargin: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    expTypography: {
      fontSize: "12px !important",
      color: "#A8ABAD",
      marginLeft: "6px",
    },
    headerWraperLoading: {
      width: "100%",
      height: "213px",
      backgroundColor: "linear-gradient(89.82deg, #F0F0F0 0%, #FAFAFA 100%)",
    },
    wrapper: {
      padding: "0 16px",
    },
    searchWrapperLoading: {
      backgroundColor: "#ffffff",
      boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.04)",
      borderRadius: 10,
      marginTop: "-58px",
      padding: 16,
      position: "relative",
      zIndex: 1,
    },
    searchLabelLoading: {
      width: "100%",
      display: "flex",
      borderBottom: "1px solid rgba(0,0,0,.08)",
      marginBottom: 8,
      alignItems: "center",
      paddingBottom: "15px",
    },
    cardBanner: {
      boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.1)",
      borderRadius: 10,
      width: "100%",
      height: "141px",
      backgroundSize: "cover",
      position: "relative",
      overflow: "hidden",
      cursor: "pointer",
    },
    promoDescription: {
      display: "flex",
      justifyContent: "space-between",
    },
  })
);

export default useStyles;
