import { axiosInstance } from "../config/axios";

export interface GetGuestVoucherResponse {
    data: GetGuestVoucherData[];
    meta: GetGuestVoucherMeta;
}

export interface GetGuestVoucherData {
    id:                    string;
    title:                 string;
    code:                  string;
    desc:                  string;
    isEnable:              boolean;
    individualUse:         boolean;
    usageLimit:            null;
    usageLimitPerUser:     null;
    amount:                number;
    discountType:          string;
    minimumSubtotalAmount: number;
    maximumSubtotalAmount: null;
    maximumDiscountAmount: null;
    expiredAt:             Date;
    startAt:               null;
    hotels:                any[];
    excludeHotels:         any[];
    rooms:                 any[];
    excludeRooms:          any[];
    usage:                 any[];
    priceInPoint:          number;
    expiryDuration:        number;
    expiryType:            string;
}

export interface GetGuestVoucherMeta {
    page:        number;
    per_page:     number;
    total_data:   number;
    message:     string;
    success_code: string;
    status_code:  number;
    total_page:   number;
}

export interface GetGuestVoucherParams {
    page: string,
    per_page: string,
    search?: string
}

export interface GetVoucherByIdResponse {
    data: GetGuestVoucherData
    meta: GetVoucherByIdMeta
}

export interface GetVoucherByIdMeta {
    message: string;
    status_code: number
}

export const GetGuestVoucher = async ({page, per_page, search}: GetGuestVoucherParams): Promise<GetGuestVoucherResponse> => {
    const response: GetGuestVoucherResponse = await axiosInstance.get("/guest/vouchers/owned", {
        params: {
            page,
            per_page,
            search
        }
    })
    return response
}

export const getVoucherById = async (id: string): Promise<GetVoucherByIdResponse> => {
    const response: GetVoucherByIdResponse = await axiosInstance.get(`/guest/vouchers/${id}/detail`)
    return response
}
